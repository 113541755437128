<script lang="ts" setup>
const emit = defineEmits<{ (event: "handleClick"): void }>();
withDefaults(
	defineProps<{
		title?: string;
		tidTitle?: string;
		tidShow?: string;
		icon?: string;
		isShowAll?: boolean;
	}>(),
	{ title: "", icon: "home/promotion" }
);

const { t } = useT();
</script>

<template>
	<div v-if="title || isShowAll" class="header">
		<AText
			v-if="title"
			class="title text-cannes"
			variant="tamale"
			:modifiers="['capitalize']"
			as="h3"
			:data-tid="tidTitle"
		>
			<ASvg v-if="icon" :name="icon" class="text-caracas icon" />
			<span>{{ title }}</span>
		</AText>
		<AText
			v-if="isShowAll"
			variant="toledo"
			:modifiers="['underline']"
			class="show-all"
			:data-tid="tidShow"
			@click="emit('handleClick')"
		>
			{{ t("Show all") }}
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.icon {
	font-size: 32px;

	@include media-breakpoint-down(md) {
		font-size: 26px;
	}
}

.show-all {
	color: var(--neutral-200);
	cursor: pointer;

	@include media-breakpoint-down(md) {
		font-size: 14px;
	}

	&:hover {
		text-decoration: none;
	}
}

.title {
	display: flex;
	align-items: center;
	gap: 8px;

	@include media-breakpoint-down(md) {
		font-size: 24px;
		line-height: 30px;
	}

	.icon {
		transform-origin: center;
		animation: swingAndScale 3s ease-in-out infinite;
	}
}

@keyframes swingAndScale {
	0%,
	100% {
		transform: scale(1);
	}

	25% {
		transform: scale(1.4);
	}

	50% {
		transform: rotate(-10deg);
	}

	75% {
		transform: rotate(10deg);
	}
}
</style>
